import React, { useEffect, useState, useContext } from 'react';
import { AccountWrapper } from 'ToolboxComponents/webapp-light/layout/menu/components/account/account.styles';
import { WhiteLabelContext, WebappContext } from 'ToolboxUtils/web/context/context';
import Avatar from 'ToolboxAssets/img/default-photo.png';
import PropTypes from 'prop-types';
import getPathLight from 'ToolboxUtils/web/get-path-light';
import { useHistory } from 'react-router-dom';

const Account = props => {
  const history = useHistory();
  const { account,
    isMobile,
    theme,
    setBodyClicked,
  } = props;
  const [selectedTab, setSelectedTab] = useContext(WebappContext).usePath('selectedTab',);
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [modalSignIn, setModalSignIn] = useContext(WebappContext).usePath('modals.signIn');

  const goTo = page => {
    if (!account) {
      setModalSignIn({opened: true, from: 'home'});
    } else {
      setSelectedTab(page);
      if (!window.location.href.endsWith('/compte')) {
        history.push(getPathLight('/compte', whiteLabel));
      }
    }
  }

  return (
      <AccountWrapper isMobile={isMobile} theme={theme}>
        <div onClick={() => goTo('profile')}>
          <div className='account-block'>
            <img className='picture' src={account && account.photo ? account.photo : Avatar} alt='User Account' />
          </div>
         </div>
      </AccountWrapper>
  );
};

Account.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  setBodyClicked: PropTypes.func.isRequired,
  openProfile: PropTypes.func.isRequired,
  openSecrets: PropTypes.func.isRequired,
  openFavorites: PropTypes.func.isRequired,
  onCreateSecret: PropTypes.func.isRequired,
  setStateAccount: PropTypes.func.isRequired,
};

export default Account;
