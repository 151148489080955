import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, WhiteLabelContext, WebappContext } from 'ToolboxUtils/web/context/context';
import { ContentWrapper } from 'ToolboxComponents/webapp-light/layout/content/content.styles';
import CookieBand from 'ToolboxComponents/webapp-light/layout/content/components/cookie-band/cookie-band';
import {COOKIE_UNSET, COOKIE_STATS_DENIED, COOKIE_STATS_ALLOWED} from 'ToolboxParams/cookies'; 

const Content = ({ children, fullContent, openCookieModal }) => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [cookieGpdr] = useContext(WebappContext).usePath("cookieGpdr");

  return (
    <ContentWrapper theme={theme} whiteLabel={whiteLabel}>
      <div className={!fullContent && 'content'}>{children}</div>
    {cookieGpdr === COOKIE_UNSET && <CookieBand openCookieModal={openCookieModal} />}
    </ContentWrapper>
  );
};


export default Content;
