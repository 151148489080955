import getUrlParameters from "../get-url-parameters";

export default function makeAnalytics(config) {
  const ga = (config && config.ga) || {
    pageview: (...args) => {},
    gtag: (...args) => {}
  }

  if (config && !config.hasGtag) {
    ga.gtag = (...args) => {};
  }

  const wrapper = {
    pageview: (...args) => {
      ga.pageview(...args);
    },

    gtag: (...args) => {
      ga.gtag(...args);
    },

    sendSearchPageView: context => {
      getUrlParameters(context);
      ga.pageview('/recherche?' + getUrlParameters(context));
    },
 
    sendProductEvent: (action, product) => {
      const {id, title, city} = product;
      const label = title + '_' + id + '_' + city;
      const category = 'POI';
      ga.gtag('event', action, {event_category: category, event_label: label});
    },
  
    sendContactEvent: (action, office) => {
      const officeName = office ? office.name : 'OT_non_chargé';
      const label = officeName;
      const category = 'Contact';
      ga.gtag('event', action, {event_category: category, event_label: label});
    },
  
    sendInteractionsEvent: (action, product) => {
      const category = 'Interactions';
      let label = window.location.href;
      if (product !== undefined) {
        const {id, title, city} = product;
        label = title + '_' + id + '_' + city;
      }
      ga.gtag('event', action, {event_category: category, event_label: label});
    },
  
    sendInteractionsHomepageEvent: (action, label) => {
      const category = 'Interactions Homepage';
      ga.gtag('event', action, {event_category: category, event_label: label || window.location.href});
    },
  }

  return {
    ...wrapper,

      // send products events

    sendMailProductEvent: (product) => {
      return wrapper.sendProductEvent('clic_mail', product);
    },

    sendPhoneProductEvent: (product) => {
      return wrapper.sendProductEvent('clic_tel', product);
    },

    sendBookingProductEvent: (product) => {
      return wrapper.sendProductEvent('clic_reserver', product);
    },

    sendItineraryProductEvent: (product) => {
      return wrapper.sendProductEvent('clic_itineraire', product);
    },

    sendWebsiteProductEvent: (product) => {
      return wrapper.sendProductEvent('clic_site_web', product);
    },

    sendMessengerProductEvent: (product) => {
      return wrapper.sendProductEvent('clic_messenger', product);
    },

    sendAddressProductEvent: (product) => {
      return wrapper.sendProductEvent('clic_adresse', product);
    },

    // send contact events

    sendAddressContactEvent: (office) => {
      return wrapper.sendContactEvent('clic_adresse', office);
    },

    sendMessengerContactEvent: (office) => {
      return wrapper.sendContactEvent('clic_messenger', office);
    },

    sendSmsContactEvent: (office) => {
      return wrapper.sendContactEvent('clic_sms', office);
    },

    sendMailContactEvent: (office) => {
      return wrapper.sendContactEvent('clic_mail', office);
    },

    sendChatContactEvent: (office) => {
      return wrapper.sendContactEvent('clic_chat', office);
    },

    sendPhoneContactEvent: (office) => {
      return wrapper.sendContactEvent('clic_tel', office);
    },

    // send interactions events

    sendInscriptionInteractionsEvent: () => {
      return wrapper.sendInteractionsEvent('inscription');
    },

    sendConnexionInteractionsEvent: () => {
      return wrapper.sendInteractionsEvent('connexion');
    },

    sendFavoriteListingInteractionsEvent: (product) => {
      return wrapper.sendInteractionsEvent('favori_page_listing', product);
    },

    sendFavoriteHomeInteractionsEvent: (product) => {
      return wrapper.sendInteractionsEvent('favori_home', product);
    },

    sendFavoriteProductInteractionsEvent: (product) => {
      return wrapper.sendInteractionsEvent('favori_fiche_produit', product);
    },

    // send interactions Homepage events

    sendEvenementInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_bloc_evenement');
    },

    sendVoirEvenementInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_bloc_evenement', 'bouton_voir_tous');
    },

    sendFMAInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_bloc_fetes&manifs');
    },

    sendProduitsInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_bloc_produits');
    },

    sendEnviesInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_bloc_envies');
    },

    sendVoirEnviesInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_bloc_envies', 'bouton_voir_toutes');
    },

    sendVireesInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_bloc_parcours');
    },

    sendSecretsInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_bloc_secrets');
    },

    sendDestinationInteractionsHomepageEvent: () => {
      return wrapper.sendInteractionsHomepageEvent('clic_destination_en_savoir_plus');
    },
  }
}
