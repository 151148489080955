import React, { useContext } from 'react';
import { LanguageContext, ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import WhiteLabelWrapper from './white-label.style';
import qualityLogo from 'ToolboxAssets/img/logo-qt.png';


const WhiteLabel = () => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  return (
    <WhiteLabelWrapper theme={theme}>
      <div className="white-label">
        <div className="catch-phrase"> 
          Ce site est fièrement propulsé par la super équipe de l'office de tourisme
          {/* <Text path='page.footer.whiteLabelText' data={{ name: whiteLabel.name }} /> */}
        </div>
        <div className="identity">
          <div className="address">
            <a href={whiteLabel.webappLight.flagshipUrl} target="_blank">
              <img className='logo' src={whiteLabel.webappLight.flagshipLogo} />
            </a>
          </div>
         </div>
      </div>
    </WhiteLabelWrapper>
  );
};

export default WhiteLabel;
