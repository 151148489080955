import React, { useState, useContext } from 'react';
import { ThemeContext, LanguageContext } from "ToolboxUtils/web/context/context";
import ButtonLink from 'ToolboxComponents/commons/buttons/button-link/button-link';
import styled from "styled-components";
import Avatar from 'ToolboxAssets/img/default-photo.png';

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 10px;
  max-width: 100%;

  &:hover {
    cursor: pointer;
  }
  .icon {
    font-size: 36px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    height: 60px;
    width: 2px;
    background: white;
  }
  
  .account-block {
    display: flex;
    align-items: center;
  }
  

  .picture {
    border: 2px solid white;
    border-radius: 50%;
    height: ${props => (props.isMobile ? '40px' : '60px')};
    width: ${props => (props.isMobile ? '40px' : '60px')};
    object-fit: cover;
  }
`;

const Login = ({ isMobile, onLogin, theme }) => {
  const [language] = useContext(LanguageContext).usePath();
  const [login] = useState({
    fr: 'Se connecter',
    en: 'Log in'
  }[language])

  return (
    <LoginWrapper className='account-block' isMobile={isMobile} theme={theme} onClick={() => onLogin()}>
        <div className='text'>{login}</div>
        <img className='picture' src={Avatar} alt={login} />
        <span className='adn adn-chevron-down icon'></span>
    </LoginWrapper>
  );
};

export default Login;
