import styled from "styled-components";
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const HandMadeWrapper = styled.div`
  font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};
  color: white;
  text-align: center;
  line-height: 20px;

  .icon {
    font-size: 40px;
    color: ${props => props.theme.color.primaryColor};
    line-height: 50px;
    vertical-align: middle;
  }

  @media (max-width: ${isMobileBreakpoint}px) {
    width: 90%;
    font-size: 16px;
    .icon {
      line-height: 27px;
      font-size: 30px;
    }
  }
`;

export default HandMadeWrapper;
