import React from 'react';


export default {
  'account-confirm': {
    route: '/confirmation-compte/:token',
    Component: React.lazy(() => import('App/root/account-confirm/account-confirm')),
  },
  account: {
    route: '/compte',
    Component: React.lazy(() => import('App/root/account/account')),
  },
  'reset-password': {
    route: '/reinitialisation-mdp/:token',
    Component: React.lazy(() => import('App/root/reset-password/reset-password')),
  },
  cookies: {
    route: '/cookies',
    Component: React.lazy(() => import('App/root/cookies/cookies')),
  },
  cgu: {
    route: '/cgu',
    Component: React.lazy(() => import('App/root/cgu/cgu')),
  },
  cgv: {
    route: '/cgv',

    Component: React.lazy(() => import('App/root/cgv/cgv')),
  },
  privacy: {
    route: '/confidentialite',
    Component: React.lazy(() => import('App/root/privacy/privacy')),
  },
  legals: {
    route: '/mentions-legales',
    Component: React.lazy(() => import('App/root/legals/legals')),
  },
  homepage: {
    route: '/',
    Component: React.lazy(() => import('App/root/home/home')),
  },
};
