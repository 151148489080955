import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const WhiteLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  padding: 30px 0;
  .white-label {
    display: flex;
    flex-direction: column;
    align-items: center;

    .catch-phrase {
      display: flex;
      flex: 50%;
      font-size: ${props => `${18*props.theme.font.secondaryFont.scaling/100}px`};
      font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
      text-transform: uppercase;
      text-align: center;
      align-items: center;
    }

    .identity {
      flex: 50%;
      text-align: center;
      padding-top: 8px;
    }

    .address {
      margin-top: 8px;
      a {
        color: black;
        transition: color 0.1s;
        .logo {
          max-width: 250px;
          max-height: 125px;
          object-fit: contain;
        }

        &:hover {
          color: ${props => props.theme.color.primaryColor};
          text-decoration: none;
        }
      }
    }
    .qualityLogo {
      margin-top: 16px;
      max-width: 250px;
      max-height: 125px;
      object-fit: contain;
    }

  }

  @media (min-width: ${isMobileBreakpoint}px) {
    padding: 70px 0;

    .logo, .qualityLogo {
      margin-top: 0;
    }
    .white-label {
      flex-direction: row;
    }
    .playlist {
      flex: 0 0 455px;
    }
    .catch-phrase {
      padding: 0 20px;
    }
  }
`;

export default WhiteLabelWrapper;
