import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, WhiteLabelContext, WebappContext } from 'ToolboxUtils/web/context/context';
import {COOKIE_UNSET, COOKIE_STATS_DENIED, COOKIE_STATS_ALLOWED} from 'ToolboxParams/cookies';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import styled from 'styled-components';

const CookieBandWrapper = styled.div`
  font-size: 15px;
  left: 0;
  bottom:0;
  position: fixed;
  z-index: 20000000;
  width: 100%;
  box-shadow: 0 -10px 50px rgba(0,0,0,0.3);
  box-sizing: border-box;
  padding: 20px 30px 20px 20px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

 
  .buttons {
    display: flex;
    justify-content: space-between;  
    @media (max-width: 767px) {
      flex-direction : column;
    }

    .button {
      margin-left: 30px;
      padding: 10px;
      font-weight: bold;
      color: white;
      white-space: nowrap;
      text-shadow: 0 0 1px 0;
      cursor: pointer;
    }

    .allow {
      background-color: ${props => props.theme.color.primaryColor};
    }

    .deny {
      background-color: ${props => props.theme.color.secondaryColor};
    }

    .customize {
      border: 1px solid ${props => props.theme.color.secondaryColor};
      color: ${props => props.theme.color.secondaryColor};
    }
  }

  @media (max-width: 767px) {
    flex-direction : column;
    text-align : center;

    .buttons {
      width: 90%;
      margin-top: 5px;

      .button {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }

`;


const setCookie = id => {
  document.cookie = "chuchoteurs" + window.location.hostname + "Gpdr=" + id + ";expires=Fri, 31 Dec 2100 23:59:59 GMT; path=/";
}

const CookieBand = props => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [cookieModal, setCookieModal] = useContext(WebappContext).usePath('modals.cookieModal');
  const [cookieGpdr, setCookieGpdr] = useContext(WebappContext).usePath("cookieGpdr");


  return (
    <CookieBandWrapper theme={theme}> 
      <span> 
            {whiteLabel.textualContent.generic.cookies.description} 
      </span>
      <div className="buttons">
        <div className="button allow" onClick={() => {setCookie(COOKIE_STATS_ALLOWED); setCookieGpdr(COOKIE_STATS_ALLOWED)}}>       
          {whiteLabel.textualContent.generic.cookies.allow}   
        </div>   
        <div className="button deny" onClick={() => {setCookie(COOKIE_STATS_DENIED); setCookieGpdr(COOKIE_STATS_DENIED)}}>       
          {whiteLabel.textualContent.generic.cookies.deny}   
        </div>   
        <div className="button customize" onClick={() => {props.openCookieModal(true)}}>      
          {whiteLabel.textualContent.generic.cookies.customize}   
        </div>
      </div>
    </CookieBandWrapper>
  )
}


export default CookieBand;