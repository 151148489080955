import React, { useEffect, useState, useContext, Suspense  } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { WhiteLabelContext, WebappContext } from "ToolboxUtils/web/context/context";
import ScrollToTop from 'ToolboxUtils/web/components/scroll-to-top';
import Menu from 'App/root/menu-with-modals/menu-with-modals';
import Footer from 'ToolboxComponents/webapp-light/layout/footer/footer';
import Content from 'ToolboxComponents/webapp-light/layout/content/content';
import Body from 'ToolboxComponents/webapp-light/layout/body/body';
import routes from './routes';

const HomepageComponent = routes["homepage"].Component;

const Page = props => {
  const history = useHistory();
  const [cookieModal, setCookieModal] = useContext(WebappContext).usePath('modals.cookieModal');
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [pages] = useState([
    ...Object.values(routes).filter(x => x.route !== '/').map(({ route, Component }) =>
      <Route
        path={'*' + route}
        key={route}
        render={props => <Component {...props} />}
      />),
      <Route
        exact path={'/'}
        key={"homepage"}
        render={props => <HomepageComponent {...props} />}
      />
  ]);

  return (
    <ScrollToTop doScroll={true}>
      <Body>
        <Menu />
        <Content
          openCookieModal={() => {setCookieModal(true)}}
        >
          <Switch>
            <Suspense fallback={<div></div>}>
              {pages}
            </Suspense>
          </Switch>
        </Content>
        <Footer />
      </Body>
    </ScrollToTop>
  );
};

export default withRouter(Page);
