import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const NavWrapper = styled.nav`
  font-family: ${props =>
    props.theme.font.menuFont !== undefined && props.theme.font.menuFont.name};
  background: white;
  height: 60px;
  left: 0;
  right: 0;
  z-index: 10;
  max-width:100%;
  
  .grow {
    flex-grow: 1;
  }

  .container {
    display: flex;
    z-index: ${props => props.isMobile ? '-1' : '30'};
    width: 100%;
    max-width: 100%;
    height: 100%;

    // @media (min-width: 576px) {
    //   max-width: 540px;
    // }
    //
    // @media (min-width: 768px) {
    //   max-width: 720px;
    // }
    //
    // @media (min-width: 992px) {
    //   max-width: 960px;
    // }
    //
    @media (min-width: 1200px) {
      max-width: 1140px;
      padding: 0;
    }
  }

  &.active {
    background: green;
    position: absolute;
    bottom: ${props => props.headerHeight}px;
  }

  & > div {
    padding: 10px;
    flex-grow: 1;
  }

  .logo {
    display: flex;
    align-items: center;

    img {
      object-fit: contain;
      height: 45px;
      padding: 0 20px;
    }
  }

  .select-languages {
    align-self: center;
  }

  .nav-items {
    display: flex;
    justify-content: 'center';
    padding: 10px 0;
    line-height: 1;
    margin-left: 25px;
    
    :last-child {
      color: ${props => props.theme.color.primaryColor};
    }
  }
  .item {
    display: flex;
    flex-flow: column;
    flex: 1 1 0px;
    justify-content: start;
    align-items: center;
    color: ${props => props.theme.color.secondaryColor};
    margin: 0 10px;
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)};
    cursor: pointer;
    border-bottom: transparent solid 3px;
    &:hover {
      text-decoration: none;
      border-bottom: ${props => props.theme.color.primaryColor} solid 3px;
    }

    .icon {
      font-size: 36px;
      display: flex;
      vertical-align: bottom;
    }
    
     

  }
  button.item {
    background: none;
    border: none;
    &:hover {
      background: ${props => props.theme.color.secondaryColor};
    }

    &.selected {
      background: ${props => props.theme.color.secondaryColor};
    }
  }

  #mobile-nav {
    background: ${props => props.theme.color.secondaryColor};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    z-index: 30;

    .item {
      padding: 5px;
      color: white;
      text-align: center;
      margin:0;
      &:hover {
        color: white;
      }

      &.selected {
        color: ${props => props.theme.color.primaryColor};
      }

      .icon {
        font-size: 34px;
        line-height: 1;
        vertical-align: bottom;
      }

      .title {
        font-size: ${props => `${(12 * props.theme.font.menuFont.scaling) / 100}px`};
        white-space: nowrap;
      }
    }
  }

  .item {
    min-width: 156px;
  }

  .item-secret {
    min-width: 250px;
  }

  .sticky-menu {
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 0;
    right: 0;
    height: 64px;
    width: 100%;
    background: #404040;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .item {
      min-width: 0;
      width: 33%;
      color: white;
      font-size: ${props => `${16*props.theme.font.primaryFont.scaling/100}px`};;
    }
  }


  @media (min-width: ${isMobileBreakpoint}px) {
    height: 80px;
    .item {
      flex-flow: row;

      .title {
        font-size: ${props => `${(16 * props.theme.font.menuFont.scaling) / 100}px`};
        font-weight: ${props => parseInt(props.theme.font.menuFont.weight)};
        margin-left: 5px;
      }
    }
    .logo {
      margin-right: 25px;
      img {
        padding: 0 10px 0 10px;
      }
    }
    .container{   
      padding: 0 10px;
    }
  }

`;
