import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import FooterWrapper from './footer.style';

import WhiteLabel from './components/white-label/white-label';
import HandMade from './components/hand-made/hand-made';

import SliderLegal from './components/slider-legal/slider-legal';

const Footer = () => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const legal = [
    { path: '/mentions-legales', text: 'Mentions légales'},
    { path: '/cgu', text: 'CGU Données personnelles'},
    { path: '/cgv', text: 'CGV Données personnelles'},
    { path: '/confidentialite', text: 'Politiques de confidentialité'},
    { path: '/cookies', text: 'Politique de cookies'},
  ];

 
  return (
    <FooterWrapper className={`footer`} theme={theme}>
      {((window.location.pathname+window.location.search) === '/') && <div className="white-label">
        <WhiteLabel />
      </div>}
      <div className="footer">
        <div className="need">
          Besoin d'aide ou d'informations complementaires ?
        </div>
        <a href={"mailto:" + whiteLabel.webappLight.mail} className="button-contact">
          CONTACTEZ-NOUS
        </a>
        <HandMade theme={theme} />
        <SliderLegal legal={legal} />
      </div>
    </FooterWrapper>
  );
};

Footer.propTypes = {
};

export default Footer;
