import React, { useRef, useEffect, useState, useContext } from 'react';
import { AccountContext, ThemeContext, WebappContext, WhiteLabelContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import { NavWrapper } from 'ToolboxComponents/webapp-light/layout/menu/menu.styles';
import Account from 'ToolboxComponents/webapp-light/layout/menu/components/account/account';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import getPathLight from 'ToolboxUtils/web/get-path-light';
import Login from 'ToolboxComponents/webapp-light/layout/menu/components/login';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


import PropTypes from 'prop-types';

const Menu = ({ selectedItem, onLogin, onCreateSecret, openFavorites, openSecrets, openProfile }) => {
  const history = useHistory();
  const theme = useContext(ThemeContext).state;
  
  const [selectedTab, setSelectedTab] = useContext(WebappContext).usePath('selectedTab',);
  const [isMobile] = useState(isMobileView());
  const [stateAccount, setStateAccount] = useContext(AccountContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [isBodyClicked, setBodyClicked] = useState(false);
  const [modalSignIn, setModalSignIn] = useContext(WebappContext).usePath('modals.signIn');


  const [, setForceUpdateCount] = useState(null);
  const [account, setAccount] = useState(stateAccount ? stateAccount : undefined);

  const isMenuTravel = useRef(true);

  useEffect(() => {
    setAccount(stateAccount);
  }, [stateAccount]);

  const forceUpdate = () => {
    setForceUpdateCount(isMenuTravel.current);
  };

  const goTo = page => {
    if (!account) {
      setModalSignIn({opened: true, from: 'home'});
    } else {
      setSelectedTab(page);
      if (!window.location.href.endsWith('/compte')) {
        history.push(getPathLight('/compte', whiteLabel));
      }
    }
  }

  return (
    <NavWrapper
      id='main-nav'
      isMobile={isMobile}
      theme={theme}
    >
      <div className='container'>
        <Link to={'/'} className='logo'>
          <img src={whiteLabel.webappLight.logo} alt='logo' />
        </Link>
        <div className='grow'></div>
        {isMobile !== true &&
          <>
            <div className='nav-items'>
              <Link key={1} className={`item`} onClick={() => {goTo('secrets')}}>
                  <span className={`adn adn-magnify icon`} />
                  <div className='title'>MES SECRETS</div>
              </Link>
              <Link key={2} className={`item`} onClick={() => {goTo('profile')}}>
                  <span className={`adn adn-star icon`} />
                  <div className='title'>MON PROFIL</div>
              </Link>
              <div key={3} className='item item-secret' onClick={() => onCreateSecret()}>
                <span className={`adn adn-plus-circle-outline icon`} />
                <div className='title'>CHUCHOTER UN SECRET</div>
              </div>
            </div>
          </>
        }
          <Account
              account={account}
              isMobile={isMobile}
              theme={theme}
              setBodyClicked={setBodyClicked}
              openProfile={openProfile}
              openSecrets={openSecrets}
              openFavorites={openFavorites}
              onCreateSecret={onCreateSecret}
              setStateAccount={setStateAccount}
          />
        {isMobile && 
          <div className='sticky-menu'>
              <Link key={1} className={`item`} onClick={() => {goTo('secrets')}}>
                  <span className={`adn adn-magnify icon`} />
                  <div className='title'>Mes secrets</div>
              </Link>
              <Link key={2} className={`item`} onClick={() => {goTo('profile')}}>
                  <span className={`adn adn-star icon`} />
                  <div className='title'>Mon profil</div>
              </Link>
              <div key={3} className='item item-secret' onClick={() => onCreateSecret()}>
                <span className={`adn adn-plus-circle-outline icon`} />
                <div className='title'>Chuchoter</div>
              </div>            
          </div>
        }
      </div>
    </NavWrapper>
  );
};

Menu.propTypes = {
  isFixed: PropTypes.bool,
  onCreateSecret: PropTypes.func,
  onLogin: PropTypes.func,
  onSelectLanguage: PropTypes.func, //call back when user selects language
  openFavorites: PropTypes.func,
  openProfile: PropTypes.func,
  openSecrets: PropTypes.func,
  saveTextualContent: PropTypes.func,
  selectedItem: PropTypes.string, //what menu item should be highlighted
};

export default Menu;
