import styled from 'styled-components';

const SliderWrapper = styled.div`
  position: relative;
  z-index: 0;
  width: ${props => props.width};
  height: ${props => props.height};
  scrollbar-width: none;
  user-select: none;

  padding: ${props =>
    (props.isMobile === true && props.computedMobile.arrows === false && '0') ||
    (props.isMobile === false &&
      props.computedDesktop.arrows === false &&
      '0') ||
    (props.isMobile === true &&
      (props.computedMobile.arrowsInside === true ||
        props.computedMobile.arrowsMiddle === true) &&
      '0') ||
    (props.isMobile === false &&
      (props.computedDesktop.arrowsInside === true ||
        props.computedDesktop.arrowsMiddle === true) &&
      '0') ||
    '0 60px'};

  &:before,
  &:after {
    content: '';
    width: 10px;
    display: none;
    display: ${props =>
      props.isMobile === false &&
      props.computedDesktop.sideFade === true &&
      'block'};
    display: ${props =>
      props.isMobile === true &&
      props.computedMobile.sideFade === true &&
      'block'};
    position: absolute;
    height: 100%;
    z-index: 2;
  }

  &:before {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }

  &:after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    top: 0;
    right: ${props =>
      (props.isMobile === true &&
        props.computedMobile.arrows === false &&
        '0') ||
      (props.isMobile === false &&
        props.computedDesktop.arrows === false &&
        '0') ||
      (props.isMobile === true &&
        props.computedMobile.arrowsInside === true &&
        '0') ||
      (props.isMobile === false &&
        props.computedDesktop.arrowsInside === true &&
        '0') ||
      '60px'};
  }

  .swiper-container {
    height: 100%;
    padding: ${props =>
      (props.isMobile === true && props.computedMobile.slidesPadding) ||
      (props.isMobile === false && props.computedDesktop.slidesPadding)}px;
    padding-left: ${props =>
      (props.isMobile === true &&
        props.computedMobile.slidesPadding < 10 &&
        props.computedMobile.sideFade === true &&
        '10') ||
      (props.isMobile === false &&
        props.computedDesktop.slidesPadding < 10 &&
        props.computedDesktop.sideFade === true &&
        '10')}px;
    padding-right: ${props =>
      (props.isMobile === true &&
        props.computedMobile.slidesPadding < 10 &&
        props.computedMobile.sideFade === true &&
        '10') ||
      (props.isMobile === false &&
        props.computedDesktop.slidesPadding < 10 &&
        props.computedDesktop.sideFade === true &&
        '10')}px;
    padding-bottom: ${props =>
      (props.isMobile === true && props.computedMobile.slidesPaddingBottom) ||
      (props.isMobile === false &&
        props.computedDesktop.slidesPaddingBottom)}px;
  }

  .swiper-pagination-design {
    position: relative;
    z-index: 1;
    top: ${props =>
      (props.isMobile === true &&
        props.computedMobile.dotsInside === true &&
        props.isNavigationCouldShowMobile === true &&
        `-${props.dotsSize + 10}px`) ||
      (props.isMobile === false &&
        props.computedDesktop.dotsInside === true &&
        props.isNavigationCouldShowDesktop === true &&
        `-${props.dotsSize + 10}px`) ||
      '5px'};

    margin: ${props =>
      (props.isMobile === true &&
        props.computedMobile.dots === true &&
        props.isNavigationCouldShowMobile === true &&
        '0 0 40px 0') ||
      (props.isMobile === false &&
        props.computedDesktop.dots === true &&
        props.isNavigationCouldShowDesktop === true &&
        '0 0 40px 0') ||
      '0 0 -20px 0'};

    display: ${props =>
      (props.isMobile === true &&
        (props.computedMobile.dots === false ||
          props.isNavigationCouldShowMobile === false) &&
        'none') ||
      (props.isMobile === false &&
        (props.computedDesktop.dots === false ||
          props.isNavigationCouldShowDesktop === false) &&
        'none') ||
      'block'};
  }

  .swiper-pagination-bullet {
    background: ${props => props.dotsColor};
    width: ${props => props.dotsSize}px;
    height: ${props => props.dotsSize}px;
    opacity: 1;
    margin: 0 2px;
    // left: 56px !important;

    &.swiper-pagination-bullet-active {
      background: ${props => props.dotsColorActive};
    }
  }

  .swiper-button {
    height: ${props => props.arrowsWidth}px;
    width: ${props => props.arrowsHeight}px;
    background: ${props => props.arrowsBackground};
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    // top: ${props => props.swiperWrapperHeight / 2}px;
    top: 
    ${props =>
      (props.isMobile === true &&
        !!props.computedMobile.arrowsTop === true &&
        props.computedMobile.arrowsTop) ||
      (props.isMobile === false &&
        !!props.computedDesktop.arrowsTop === true &&
        props.computedDesktop.arrowsTop) ||
      `calc(50% - ${(props.isMobile === true &&
        props.computedMobile.dots === true &&
        '30px') ||
        (props.isMobile === false &&
          props.computedDesktop.dots === true &&
          '30px') ||
        '0px'})`}; 
    transform: translate(0, -50%);
    transition: opacity 0.2s;
    display: flex;
    display: ${props =>
      (props.isMobile === true &&
        props.computedMobile.arrows === true &&
        props.isNavigationCouldShowMobile === true &&
        'flex') ||
      (props.isMobile === false &&
        props.computedDesktop.arrows === true &&
        props.isNavigationCouldShowDesktop === true &&
        'flex') ||
      'none'};
    justify-content: center;
    align-items: center;
    box-shadow: ${props =>
      props.arrowsShadow === true ? 'hsla(0, 0%, 40%, 1) 1px 2px 5px' : 'none'};

    &:after {
      content: '';
    }
  }

  .swiper-button-disabled {
    opacity: ${props =>
      (props.isMobile === true &&
        props.computedMobile.arrowsDisabledHide === true &&
        '0') ||
      (props.isMobile === false &&
        props.computedDesktop.arrowsDisabledHide === true &&
        '0') ||
      '0.5'};
  }

  .swiper-button-next-design {
    right: ${props =>
      (props.isMobile === true &&
        props.computedMobile.arrowsInside === true &&
        props.isNavigationCouldShowMobile === true &&
        (props.isMobile ? props.computedMobile.arrowsOffset : props.computedDesktop.arrowsOffset) + 'px') ||
      (props.isMobile === false &&
        props.computedDesktop.arrowsInside === true &&
        props.isNavigationCouldShowDesktop === true &&
        (props.isMobile ? props.computedMobile.arrowsOffset : props.computedDesktop.arrowsOffset) + 'px') ||
      '0'};
    right: ${props =>
      (props.isMobile === true &&
        props.computedMobile.arrowsMiddle === true &&
        props.isNavigationCouldShowMobile === true &&
        '-20px') ||
      (props.isMobile === false &&
        props.computedDesktop.arrowsMiddle === true &&
        props.isNavigationCouldShowDesktop === true &&
        '-20px')};
  }
  }

  .swiper-button-prev-design {
    left: ${props =>
      (props.isMobile === true &&
        props.computedMobile.arrowsInside === true &&
        props.isNavigationCouldShowMobile === true &&
        (props.isMobile ? props.computedMobile.arrowsOffset : props.computedDesktop.arrowsOffset) + 'px') ||
      (props.isMobile === false &&
        props.computedDesktop.arrowsInside === true &&
        props.isNavigationCouldShowDesktop === true &&
        (props.isMobile ? props.computedMobile.arrowsOffset : props.computedDesktop.arrowsOffset) + 'px') ||
      '0'};
    left: ${props =>
      (props.isMobile === true &&
        props.computedMobile.arrowsMiddle === true &&
        props.isNavigationCouldShowMobile === true &&
        '-20px') ||
      (props.isMobile === false &&
        props.computedDesktop.arrowsMiddle === true &&
        props.isNavigationCouldShowDesktop === true &&
        '-20px')};
  }
`;

export default SliderWrapper;
