import styled from 'styled-components';

export const ContentWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow:clip;

`;
