import React, { useState, useEffect, useContext } from 'react';
import {Helmet} from "react-helmet";
import {Switch, Route} from 'react-router-dom';
import { ThemeContext, LanguageContext, WebappContext, GeolocationContext, WhiteLabelContext, AccountContext } from "ToolboxUtils/web/context/context";
import {COOKIE_STATS_ALLOWED} from 'ToolboxParams/cookies';
import getLanguage from 'ToolboxUtils/web/cookie/get-language';
import usePromise from 'ToolboxUtils/web/hooks/use-promise';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import Page from 'App/root/page/page';
import getCurrentPosition from 'Utils/web/geolocation/get-current-position';

let localIsGeolocated = false;
let jwtdecode = null;


const Root = props => {
  const [geolocation, setGeolocation] = useContext(GeolocationContext).usePath();
  const [whiteLabel, setWhiteLabel] = useContext(WhiteLabelContext).usePath();
  const [cookieGpdr, setCookieGpdr] = useContext(WebappContext).usePath("cookieGpdr");
  const [analytics, setAnalytics] = useContext(WebappContext).usePath("analytics");
  const [theme, setTheme] = useContext(ThemeContext).usePath();
  const [language, setLanguage] = useContext(LanguageContext).usePath();
  const [account, setAccount] = useContext(AccountContext).usePath();
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  usePromise(getCurrentPosition, {
    onComplete: async ({isError, data}) => {
      if (isError === true) {
        return;
      }

      localIsGeolocated = true;
      setGeolocation({
        coordinates: data,
        isGeolocated: true
      });
    }
  });

  useEffect(() => {
    (async () => {
      const urlPosition = window.location.href.indexOf('//');
      const url = window.location.href.substring(urlPosition + 2);
      const whiteLabel = await apiChuchoteurs.get('/white-labels', {
        params: {url: encodeURIComponent(url), filter: 'light'},
      });
      setTheme(whiteLabel.theme);
      setWhiteLabel(whiteLabel);
    })();
  }, []);
  

  // Whitelabel effects on account
  useEffect(() => {
    // account is linked only to a "master" white label (who has a domain name)
    // and not to sub-white labels, so an account can't change inside the same domain
    if (whiteLabel === undefined || account !== undefined) {
      return;
    }
    
    if (localStorage.token) {
      const decode = () => {
        const account = jwtdecode(localStorage.token);
        if(account && account.exp*1000 > Date.now()){
          const favorites = apiChuchoteurs.get('/products/', {
            params: {filter: 'account-favorites', accountId: account.id, whiteLabelId: whiteLabel.id},
            token: localStorage.token
          });
  
          Promise.all([favorites]).then(data => {
            account.favorites = data[0];
            setAccount(account);
          })
        } else {
          localStorage.clear();
        }
      }
      if (jwtdecode === null) {
        import(/* webpackChunkName: "account" */ 'jwt-decode').then(x => {
          jwtdecode = x.default;
          decode();
        });
      } else {
        decode();
      }
    }
  }, [whiteLabel]);


  // WhiteLabel effect on scripts
  useEffect(() => {
    if (whiteLabel && whiteLabel.config && whiteLabel.config.facebookId && scriptsLoaded === false) {
      setScriptsLoaded(true);
      const script = document.createElement('script');

      script.text = `
        window.fbAsyncInit = function() {
          FB.init({
            appId      : ${whiteLabel.config.facebookId},
            cookie     : false,
            xfbml      : true,
            version    : 'v14.0'
          });

          FB.AppEvents.logPageView();

        };

        (function(d, s, id){
           var js, fjs = d.getElementsByTagName(s)[0];
           if (d.getElementById(id)) {return;}
           js = d.createElement(s); js.id = id;
           js.src = "https://connect.facebook.net/fr_FR/sdk.js";
           fjs.parentNode.insertBefore(js, fjs);
         }(document, 'script', 'facebook-jssdk'));
      `;

      document.body.appendChild(script);
    }

  }, [whiteLabel, cookieGpdr]);


  useEffect(() => {
    if (localStorage.token) {
      const decode = () => {
        try {
          const account = jwtdecode(localStorage.token);
          if (account.isActivated === false) {
            async function checkAccountActivated() {
              try {
                const accountToken = await apiChuchoteurs.get('/accounts/active', {
                  token: localStorage.token
                });
                localStorage.clear();
                const data = jwtdecode(accountToken);
                localStorage.setItem('token', accountToken);
              } catch (err) {
                console.log(err);
              }
            };
            checkAccountActivated()
          }
        } catch(err) {
          console.log(err);
        }
      }
      if (jwtdecode === null) {
        import(/* webpackChunkName: "account" */ 'jwt-decode').then(x => {
          jwtdecode = x.default;
          decode();
        });
      } else {
        decode();
      }
    }
  }, []);

  return (
    whiteLabel !== undefined &&
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="apple-touch-icon" sizes="180x180" href={whiteLabel.pictures.favicon.apple} />
        <link rel="icon" type="image/png" sizes="192x192" href={whiteLabel.pictures.favicon.android192} />
        <link rel="icon" type="image/png" sizes="512x512" href={whiteLabel.pictures.favicon.android512} />
        <link rel="icon" type="image/png" sizes="32x32" href={whiteLabel.pictures.favicon.icon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={whiteLabel.pictures.favicon.icon16} /> */}
        {whiteLabel.config && whiteLabel.config.facebookDomainVerification && <meta name="facebook-domain-verification" content={whiteLabel.config.facebookDomainVerification} /> }
      </Helmet>
        <Switch>
          <Route path={'/:page'} component={Page} />
          <Route exact path={'/'} component={Page} />
        </Switch>
    </div>
  );
}


export default Root;
