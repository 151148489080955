import styled from 'styled-components';

export const AccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 10px;
  max-width: 100%;
  &:hover {
    cursor: pointer;
  }
  .icon {
    font-size: 36px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    height: 60px;
    width: 2px;
    background: white;
  }

  .account-block {
    display: flex;
    align-items: center;
  }

  .picture {
    border: 2px solid white;
    border-radius: 50%;
    height: ${props => (props.isMobile ? '54px' : '60px')};
    width: ${props => (props.isMobile ? '54px' : '60px')};
    object-fit: cover;
  }

  .text {
    padding: 0 10px;
    font-size: ${props => (props.isMobile ? `${11*props.theme.font.primaryFont.scaling/100}px` : `${15*props.theme.font.primaryFont.scaling/100}px`)};
    line-height: 1;
  }



  .account-drop-menu {
    background-color: ${props => props.theme.color.primaryColor};
    position: absolute;
    right: 0;
    top: 80px;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.15s ease-in;
    width: 211px;
    top: 80px;
    box-shadow: 0px 25px 20px 5px rgb(0 0 0 / 10%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    ul {
      list-style-type: none;
    }
    .userSettings {
      border-radius: 0 0 10px 10px;
      padding-left: 0;
      background-color: white;
      margin-bottom: 0;
      li {
        margin: 0px 20px;
        background-image: linear-gradient(to right, black 10%, rgba(255,255,255,0) 0%);
        background-position: top;
        background-size: 8px 1px;
        background-repeat: repeat-x;
        padding: 10px 20px;
        color: black;
      }
    }
  }
  .adminSettings {
    top: 120px;
    width: 211px;
    padding: 16px;
    li {
      color: white;
    }
    .backoffice {
      text-align: center;
      background-image: linear-gradient(to right, white 10%, rgba(255,255,255,0) 0%);
      background-position: bottom;
      background-size: 8px 1px;
      background-repeat: repeat-x;
      list-style-type: none;
      align-items: center;
      display: flex;
      padding-bottom: 10px;
      margin-bottom: 16px;
      p {
        margin: 0 0 0 8px;
      }
    }
    .textedit {
      text-align: center;
      color: white;
      span {
        background-color: white;
        color: black;
        border-radius: 25px;
        padding: 4px;
        &.adn-flash_on {
          color: ${props => props.theme.color.primaryColor};
        }
        &.adn-power {
          color: black;
        }
      }
    }
    .btn-save {
      background-color: white;
      color: ${props => props.theme.color.primaryColor};
      border-radius: 20px;
      width: 100%;
      text-align: center;
    }
  }

  .account-drop-menu ul li {
    cursor: pointer;
    line-height: 20px;
    padding: 10px 16px;
    &.whisper {
      background: ${props => `${props.theme.color.primaryColor}30`};
      color: ${props => props.theme.color.primaryColor};
      font-weight: bold;
    }
    &:hover {
      background: ${props => props.theme.color.primaryColor};
      color: white;
    }
    &.dropdown-title:hover {
      background: inherit;
      color: ${props => props.theme.color.primaryColor};
      cursor: default;
    }
  }
  .account-arrow-up {
    border-bottom: 7px solid white;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    height: 0;
    position: absolute;
    right: 38px;
    width: 0;
    top: -6px;
  }

  @media only screen and (min-width: 768px) {
    .content-account .content-account-inner-pseudo.active .account-drop-menu {
      right: 22px;
    }
  }

  @media only screen and (max-width: 767px) {
    .account-drop-menu {
      top: 60px;
    }

    .account-arrow-up {
      right: 27px;
    }

    .account-drop-menu li {
      font-size: 17px;
      line-height: 30px;
    }
  }

  .dropdown-title {
    display: flex;
    align-items: center;
    padding: 5px;
    padding-left: 15px;
    color: black;
    text-decoration: none;
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight) + 300};
    p {
      margin: 0;
    }
  }
  .setting {
    display: flex;
    align-items: center;
    padding: 15px;
    color: black;
    text-decoration: none;
    .save {
      width: 50px;
      margin-right: 1rem;
      justify-content: center;
      display: flex;
      font-size: 30px;
    }
    .switch {
      margin-right: 1rem;
      position: relative;
      -webkit-appearance: none;
      outline: none;
      width: 50px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #D9DADC;
      border-radius: 50px;
      box-shadow: inset -20px 0 0 0 #fff;
    }

    .switch:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      background: transparent;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
    }

    .switch:checked {
      box-shadow: inset 20px 0 0 0 #4ed164;
      border-color: #4ed164;
    }

    .switch:checked:after {
      left: 20px;
      box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
    }
  }
  button.setting {
    background: none;
    border: none;
  }
`;
