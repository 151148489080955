import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import styled from 'styled-components';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import getPathLight from 'ToolboxUtils/web/get-path-light';


const SliderLegalWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;

  .swiper-slide {
    width: auto !important;
    padding: 0 20px;
  }

  .separator {
    color: #aaa;
  }

  a {
    color: #aaa;
    transition: color 0.1s;

    &:hover {
      color: #c7c7c7;
      text-decoration: none;
    }
  }
`;

const SliderLegal = ({ legal }) => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  return (
    <SliderLegalWrapper className="slider-footer">
      {isMobile === true && (
        <div className="mobile">
          <Slider
            key={'footer-slider'}
            mobile={{
              // slidesPerView: 1.2,
              slidesPerView: 'auto',
              // freeMode: true,
              slidesPadding: 10,
              groupSliding: false,
            }}
          >
            {legal.map((info, index) => (
              <Link to={getPathLight(info.path, whiteLabel)} key={index}>
                {info.text}
              </Link>
            ))}
          </Slider>
        </div>
      )}
      {isMobile === false && (
        <div className="desktop">
          {legal.map((info, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && <span className="separator"> | </span>}
                <Link to={getPathLight(info.path, whiteLabel)} key={index}>
                  {info.text}
                  </Link>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </SliderLegalWrapper>
  );
};

SliderLegal.propTypes = {
  legal: PropTypes.array.isRequired,
};

export default SliderLegal;
